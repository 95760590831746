import React from "react"
import { Button, Segment, ButtonGroup} from 'semantic-ui-react'
import OptionButtons from './OptionButtons'

function PlayOptions({state, beginGame, updateMazeSettings}) {
  
  const updateOption = (selectedOption) => {
    updateMazeSettings({maze_rows: selectedOption, maze_columns: selectedOption})
  }
  
  const {isMultiplayer, player_name, show_maze, show_end} = state
  return (
    <div className="mt-1">
      <Segment inverted>
        <div><strong>Name</strong></div>
        <div className="mb-3">{player_name}</div>

        <Button onClick={() => beginGame()}>Play maze</Button>

        <OptionButtons optionName="Maze size" options={[5,10,15]} selectedOption={state.maze_rows} updateOption={updateOption} />

        <div className="mt-4">
          <div><strong>Show maze</strong></div>
          <ButtonGroup size='small'>
            <Button onClick={()=>updateMazeSettings({show_maze: true})} primary={show_maze === true ? true : false}>Yes</Button>
            <Button onClick={()=>updateMazeSettings({show_maze: false})} primary={show_maze === true ? false : true}>No</Button>
          </ButtonGroup>
        </div>
        <div className="mt-4">
          <div><strong>Show end</strong></div>
          <ButtonGroup size='small'>
            <Button onClick={()=>updateMazeSettings({show_end: true})} primary={show_end === true ? true : false}>Yes</Button>
            <Button onClick={()=>updateMazeSettings({show_end: false})} primary={show_end === true ? false : true}>No</Button>
          </ButtonGroup>
        </div>

      </Segment>
    </div>
  );
}

export default PlayOptions;