import './codebreaker.css';
import { Button } from 'semantic-ui-react'
import React, { useEffect, useState, useRef } from "react"
import { post } from '../utils/apiClient'
import { createCode, displayTime } from '../utils/utils'
import Records from '../components/Records'
import ButtonGrid from '../components/ButtonGrid'
import PlayOptions from '../components/Codebreaker/PlayOptions'

function Codebreaker() {
  const webSocket = useRef(null);
  const [state, setState] = useState({
    wsConnection: 'wss',
    host: null,
    player_name: null,
    beginAt: null,
    endAt: null,
    isPlaying: false,
    showingGuesses: true,
    codeToBreak: null,
    codeSolved: undefined,
    currentGuess: '',
    guesses: [],
    selectedCodeLength: 2,
    codeLength: 2,
    minCodeLength: 1,
    maxCodeLength: 5,
    selectedPlayerCount: 1,
    isMultiplayer: false,
    playerCount: 1,
    minPlayerCount: 1,
    maxPlayerCount: 2,
    currentGroup: {},
    multiplayerGroups: {},
    records: []
  })

  const mount = () => {
    async function load() {
      let res = await post('/api/codebreaker/records', {});
      const player_name = `Guest${Math.floor(Math.random() * 100) + 1}`
      const host = window.location.host.replace(':4001', ':4000')
      setState({...state, player_name, 
        records: res.records ?? [], 
        host,
        wsConnection: host.slice(-5) === ':4000' ? 'ws' : 'wss'
      })
    }
    load()
    return () => {
      if (webSocket.current) {
        webSocket.current.close()
        webSocket.current = null
      }
    }
  }
  useEffect(mount, [])

  const beginGameObject = (codeLength=null, codeToBreak=null) => {
    if (!codeLength) codeLength = state.selectedCodeLength
    if (!codeToBreak) codeToBreak = createCode(codeLength)
    
    return { 
      beginAt: Date.now(),
      codeLength,
      codeToBreak,
      isPlaying: true,
      showingGuesses: true,
      codeSolved: false,
      currentGuess: '',
      guesses: [] 
    }
  }

  const beginGame = () => {
    if (state.isMultiplayer && webSocket?.current?.readyState === 1) {
      webSocket.current.send(JSON.stringify({beginGame: true, codeLength: state.selectedCodeLengthcodeLength}))
    } else {
      setState({...state, 
        ...beginGameObject()
      })
    }
  }

  const guessCode = async (currentGuess) => {
    let {codeToBreak, guesses, codeLength, beginAt, endAt, records} = state
    if (!currentGuess) currentGuess = state.currentGuess
    let currentGuessCharacters = [...currentGuess]
    let response = []
    let codeSolved = true
    for(let i = 0; i < codeLength; i++) {
      let characterResult = {
        character: currentGuessCharacters[i],
        is_correct: true
      }
      if(codeToBreak[i] !== currentGuessCharacters[i]) {
        characterResult.is_correct = false
        codeSolved = false
      }
      response.push(characterResult)
    }
    let scroll_to_height = document.body.scrollHeight
    const guessedAt = Date.now()
    guesses.push({guessedAt, response})
    if (codeSolved) {
      endAt = guessedAt
      try {
        const res = await post('/api/codebreaker/completed', {total_time: endAt-beginAt, guesses, codeToBreak});
        records = res.records ?? records
      } catch {}
      scroll_to_height = 0
    }

    setState({...state, guesses, codeSolved, isPlaying: !codeSolved, showingGuesses: !codeSolved, endAt, currentGuess: '', records })
    if (codeSolved && state.isMultiplayer && webSocket?.current?.readyState === 1) {
      webSocket.current.send(JSON.stringify({endGame: true, total_time: endAt-beginAt, guess_count: guesses.length}))
    }
    window.scrollTo(0, scroll_to_height)
  }

  // const inputChange = (e, {name, value}) => {
  //   updateGuess(value)
  // }
  
  const hitButton = (btnValue) => {
    updateGuess(`${state.currentGuess}${btnValue}`)
  }
  const hitDelete = () => {
    updateGuess(`${state.currentGuess.length > 0 ? state.currentGuess.slice(0,-1) : state.currentGuess}`)
  }
  const updateGuess = (currentGuess) => {
    currentGuess = currentGuess.replace(/[^0-9]/g, '').substr(0, state.codeLength) // only digit and decimal
    if ( state.codeLength <= currentGuess.length) {
      guessCode(currentGuess)
    } else {
      setState({ ...state, currentGuess})
    }
  }

  const updateCodeLength = (newCodeLength) => {
    if (newCodeLength > 0 && newCodeLength <= state.maxCodeLength){
      if (state.isMultiplayer && webSocket?.current?.readyState === 1) {
        webSocket.current.send(JSON.stringify({selectedCodeLength: newCodeLength}))
      }
      setState({...state, selectedCodeLength: newCodeLength })
    } 
  }

  const newGroup = () => {
    if (state.isMultiplayer && webSocket?.current?.readyState === 1) {
      webSocket.current.send(JSON.stringify({newGroup: true}))
    }
  }
  const joinGroup = (groupName) => {
    if (state.isMultiplayer && webSocket?.current?.readyState === 1) {
      webSocket.current.send(JSON.stringify({joinGroup: groupName}))
    }
  }

  const updatePlayerCount = (newPlayerCount) => {
    if (newPlayerCount > 0 && newPlayerCount <= state.maxCodeLength) setState({...state, selectedPlayerCount: newPlayerCount })
  }

  const onMessage = async (ev) => {
    const receivedMessage = JSON.parse(ev.data)
    if (receivedMessage.beginGame) {
      // group: currentGroup
      setState((current) => { 
        return {...current, 
        ...beginGameObject(receivedMessage.codeLength, receivedMessage.codeToBreak)
      }})
    } else {
      setState((current) => { 
        return {...current, 
        selectedCodeLength: receivedMessage?.group?.selectedCodeLength || current.selectedCodeLength,
        currentGroup: receivedMessage?.group || current.currentGroup,
        multiplayerGroups: receivedMessage?.groups || current.multiplayerGroups
      }})
    }
  }

  const updateMultiplayer = async (updatedIsMultiplayer) => {
    if (state.isMultiplayer !== updatedIsMultiplayer) {
        const {isMultiplayer, player_name, wsConnection, host} = state
        if (!isMultiplayer && !webSocket.current) {
          webSocket.current = new WebSocket(`${wsConnection}://${host}/multiplayer`)
          webSocket.current.onmessage = onMessage
          webSocket.current.onopen = function (event) {
            webSocket.current.send(JSON.stringify({player_name}))
          }
          webSocket.current.onclose = function (event) {
            webSocket.current = null
            setState((current) => { return {...current, isMultiplayer: false, currentGroup: {}, multiplayerGroups: {}}})
          }
          setState({ ...state, isMultiplayer: true })
        } else if (isMultiplayer && webSocket.current) {
          webSocket.current.close()
          webSocket.current = null
          setState({...state, isMultiplayer: false})
        }
        // setState({...state, isMultiplayer: updatedIsMultiplayer })
    }
  }
  const showGame = (game) => {
    return (
      <div>
        <ol>
          {game.playerResults.map(playerUid => {
            const player = game.players[playerUid]
            return (<li key={playerUid}>
              {player.player_name}: {player.total_time ? `${displayTime(player.total_time)} - Tries: ${player.guess_count}` : '?'}  
            </li>)
          })}
        </ol>
      </div>
    )
  }

  const updatePlayer = (player_name) => {
    setState({...state, player_name})
  }

  const {isPlaying, codeToBreak, currentGuess, codeSolved, guesses, showingGuesses, beginAt, endAt, records, selectedCodeLength, currentGroup} = state
  return (
    <div>
      <h2>Codebreaker</h2>

      {codeSolved ? 
        <>
          <h3>Code solved</h3>
          <div>{displayTime(endAt-beginAt)} - {guesses.length} tries</div>
          {currentGroup?.game?.players ? 
            <div>
              {showGame(currentGroup?.game)}
            </div>
            : ''
          }
        </>
        : ''
      }
      {codeToBreak ? 
          <>
            {codeSolved ? 
              <div className='code-box'>
                {[...Array(state.codeLength)].map((code, index) => 
                  <div className='code-character' key={`${index}`}>{codeToBreak[index]}</div>
                )}
              </div>
            :
              <div className='code-box'>
                {[...Array(state.codeLength)].map((code, index) => 
                  <div className='code-character' key={`${index}`}>?</div>
                )}
              </div>
            }</>
        : ''
      }

    {!isPlaying && codeSolved ? <Button onClick={()=> setState({...state, showingGuesses: !showingGuesses})}>Show guesses</Button> : ''}
    {showingGuesses ? 
      <div className='guess-list'>
        {guesses.map(guess => 
          <div className='guess-row' key={guess.guessedAt}>
            {guess.response.map((result, index) => 
              <div key={`${index}${result.character}`} className={result.is_correct ? 'character-correct' : ''}>{result.character}</div>
            )}
          </div>
        )}

        {codeToBreak && !codeSolved ? 
          <div className='guess-row'>
              {[...Array(state.codeLength)].map((character, index) => {
                if (currentGuess[index]) return <div key={`${index}`}>{currentGuess[index]}</div>
                return <div key={`${index}`}>&nbsp;&nbsp;</div>
              })}
          </div> 
        : ''}
      </div>
      : ''
    }

    {codeToBreak && !codeSolved ? <ButtonGrid hitButton={hitButton} hitDelete={hitDelete}/> : ''}

    <div className='mt-4 mb-4'><hr></hr></div>
      {!isPlaying ? <PlayOptions 
          state={state}
          updatePlayer={updatePlayer}
          beginGame={beginGame} 
          updateCodeLength={updateCodeLength} 
          updatePlayerCount={updatePlayerCount}
          updateMultiplayer={updateMultiplayer}
          newGroup={newGroup}
          joinGroup={joinGroup}
        /> : ''
      }
      
    {isPlaying ? '' : <Records records={records.filter(r => r.code_length === selectedCodeLength )} displayTime={displayTime} />}

    </div>
  );
}

export default Codebreaker;