function getRandomNumber(range) {
  return Math.floor(Math.random() * range)
}

class Cell {
  constructor(column, row) {
      this.column = column;
      this.row = row;

      this.wallTop = true;
      this.wallRight = true;
      this.wallBottom = true;
      this.wallLeft = true; 

      this.visited = false;
  }
}

export default class CreateMaze {
  constructor(rows, columns, cellSize) {
      this.columns = columns;
      this.rows = rows;
      this.cellSize = cellSize; 
      this.finishColor = "crimson";
      this.backgroundColor = "#DCDCDC";
      this.cells = [];
      this.createMaze();   
  }

  createMaze() {
      let mazeHeight = this.cellSize * this.rows;
      let mazeWidth = this.cellSize * this.columns;
      let mazeOffset = 10;

      // canvas.height = mazeHeight + (mazeOffset * 2);
      // canvas.width = mazeWidth + (mazeOffset * 2);
      // canvas.style.height = mazeHeight + (mazeOffset * 2);
      // canvas.style.width = mazeWidth + (mazeOffset * 2);

      for (let i = 0; i < this.columns; i++) {
          this.cells[i] = [];
          for (let j = 0; j < this.rows; j++) {
              this.cells[i][j] = new Cell(i, j);
          }
      }

      let stack = [];

      let randomColumn = getRandomNumber(this.columns);
      let randomRow = getRandomNumber(this.rows);

      stack.push(this.cells[randomColumn][randomRow]);

      let currentCell;
      let nextCell;
      let neighbour;
      let option;

      while(this.hasAnyUnvisitedCells(this.cells)) {
          currentCell = stack[stack.length - 1];
          currentCell.visited = true;

          if(this.hasAnyUnvisitedNeighbours(currentCell)) {
              nextCell = null;
              neighbour = false;
              do {
                  option = getRandomNumber(4);
                  switch(option) {
                      case 0:
                          if(currentCell.row !== 0 && !this.cells[currentCell.column][currentCell.row - 1].visited) {
                              currentCell.wallTop = false;
                              nextCell = this.cells[currentCell.column][currentCell.row - 1];
                              nextCell.wallBottom = false;
                              neighbour = true;
                          }
                          break;
                      case 1:
                          if(currentCell.column !== (this.columns - 1) && !this.cells[currentCell.column + 1][currentCell.row].visited) {
                              currentCell.wallRight = false;
                              nextCell = this.cells[currentCell.column + 1][currentCell.row];
                              nextCell.wallLeft = false;
                              neighbour = true;
                          }
                          break;
                      case 2:
                          if(currentCell.row !== (this.rows - 1) && !this.cells[currentCell.column][currentCell.row + 1].visited) {
                              currentCell.wallBottom = false;
                              nextCell = this.cells[currentCell.column][currentCell.row + 1];
                              nextCell.wallTop = false;
                              neighbour = true;
                          }
                          break;
                      case 3:
                          if(currentCell.column !== 0 && !this.cells[currentCell.column - 1][currentCell.row].visited) {
                              currentCell.wallLeft = false;
                              nextCell = this.cells[currentCell.column - 1][currentCell.row];
                              nextCell.wallRight = false;
                              neighbour = true;
                          }
                          break;
                  }
                  if(neighbour) {
                      stack.push(nextCell);
                  }
              } while(!neighbour)
          }
          else {
              currentCell = stack.pop();
          }
      }
  }

  hasAnyUnvisitedCells() {
      for (let i = 0; i < this.columns; i++) {
          for (let j = 0; j < this.rows; j++) {
              if(!this.cells[i][j].visited) {
                  return true;
              }
          }
      }
  }

  hasAnyUnvisitedNeighbours(cell) {
      return ((cell.column !== 0 && !this.cells[cell.column - 1][cell.row].visited) ||
          (cell.column !== (this.columns - 1) && !this.cells[cell.column + 1][cell.row].visited) ||
          (cell.row !== 0 && !this.cells[cell.column][cell.row - 1].visited) ||
          (cell.row !== (this.rows - 1) && !this.cells[cell.column][cell.row + 1].visited));
  }

}