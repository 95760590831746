import superAgent from "superagent";

export const post = (url, data={}) => {
  try {
    let req = superAgent.post(url).send(data)
      .then(res => {
        if (!res.body) res.body = {};
        res = res.body;
        return res;
      })

    return req;
  } catch {
    return {}
  }
}