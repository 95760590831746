import './App.css';

import { Route, Routes } from "react-router-dom";

import Layout from './components/Layout/Layout'
import NoMatch from './pages/nomatch.js'
import Home from './pages/home.js'
import Codebreaker from './pages/codebreaker.js'
import Mazerunner from './pages/mazerunner.js'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="codebreaker" element={<Codebreaker />} />
          <Route path="mazerunner" element={<Mazerunner />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
