import React from "react"
import { Button, ButtonGroup } from 'semantic-ui-react'

function CodeLengthButtons({state, updateCodeLength}) {

  const codeLengthButtons = () => {
    const {selectedCodeLength, minCodeLength, maxCodeLength} = state
    let lengthButtons = []
    for(let i = minCodeLength; i <= maxCodeLength; i++) {
      lengthButtons.push(<Button onClick={()=>updateCodeLength(i)} primary={i === selectedCodeLength ? true : false} key={`b${i}`}>{i}</Button>)
    }
    return lengthButtons
  }

  return (
    <div>
      <div className="mt-3"><strong>Code length</strong></div>
      <ButtonGroup size='small'>
        {codeLengthButtons()}
      </ButtonGroup>  
    </div>
  )
}

export default CodeLengthButtons;