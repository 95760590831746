import React from "react"
import { Button, ButtonGroup } from 'semantic-ui-react'

function OptionButtons({updateOption, options, selectedOption, optionName=''}) {

  return (
    <div>
      <div className="mt-3"><strong>{optionName}</strong></div>
      <ButtonGroup size='small'>
        {options ? options.map((option, index) => 
            <Button onClick={()=>updateOption(option)} primary={option === selectedOption ? true : false} key={`i${index}`}>{option}</Button>
          )
        : ''}
        
      </ButtonGroup>  
    </div>
  )
}

export default OptionButtons;