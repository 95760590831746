import React, {useState} from "react"
import { Button, Segment, ButtonGroup, Icon } from 'semantic-ui-react'
import Multiplayer from './Multiplayer'
import CodeLengthButtons from "./CodeLengthButtons"
import PlayerModal from '../PlayerModal'

function PlayOptions({state, beginGame, updateCodeLength, updatePlayerCount, updateMultiplayer, newGroup, joinGroup, updatePlayer}) {
  
  const [editPlayer, setEditPlayer] = useState(false)
  
  const {isMultiplayer, player_name} = state
  return (
    <div className="mt-1">
      <Segment inverted>
      <Icon className="float-right" link name='edit' onClick={() => setEditPlayer(true)} />
        {editPlayer ? <PlayerModal current_name={player_name} setEditPlayer={setEditPlayer} updatePlayer={updatePlayer} /> : ''}

        <div><strong>Name</strong></div>
        <div className="mb-3">{player_name}</div>

        {isMultiplayer ? 
          <Multiplayer state={state} beginGame={beginGame} newGroup={newGroup} joinGroup={joinGroup} updateCodeLength={updateCodeLength} updatePlayerCount={updatePlayerCount} /> 
        : 
          <>
            <CodeLengthButtons state={state} updateCodeLength={updateCodeLength} />
            <Button onClick={()=>beginGame()} size='big' primary className="mt-4">Begin</Button> 
          </>
        }

        <div className="mt-4">
          <div><strong>Multiplayer</strong></div>
          <ButtonGroup size='small'>
            <Button onClick={()=>updateMultiplayer(false)} primary={isMultiplayer === true ? false : true}>No</Button>
            <Button onClick={()=>updateMultiplayer(true)} primary={isMultiplayer === true ? true : false}>Yes</Button>
          </ButtonGroup>
        </div>

      </Segment>
    </div>
  );
}

export default PlayOptions;