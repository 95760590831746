import { Link, Outlet } from "react-router-dom";
import React, { useState } from "react"
import { Menu } from 'semantic-ui-react'

function Layout(params) {
    const [activePage, setActivePage] = useState(window.location.pathname)
    const handleItemClick = (e, { to }) => setActivePage(to)
    return (
      <div>
        <Menu inverted>
        {/* <h2>Sparky Games</h2> */}
          <Menu.Item name='Sparky Games' as={ Link } to='/' link active={activePage==='/'} onClick={handleItemClick} />
          {/* <Menu.Item name='Home' as={ Link } to='/' link active={activePage==='/'} onClick={handleItemClick} />
          <Menu.Item name='Codebreaker' as={ Link } to='/codebreaker' link active={activePage==='/codebreaker'} onClick={handleItemClick} /> */}
        </Menu>
        <div className="main-content">
          <Outlet />
        </div>
        <div style={{clear:'both', paddingBottom:'70px'}}></div>
      </div>
    );
}

export default Layout;