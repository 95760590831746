import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Button, Icon } from 'semantic-ui-react';

function Home() {

  const mount = () => {
    async function load() {
    }
    load()
  }
  useEffect(mount, [])
  
  return (
    <div>
      <h2>Games</h2>
      <div className="mb-4"><Button as={Link} to={`/codebreaker`} color='vk' size='massive'><Icon name='qrcode' /> Codebreaker</Button></div>
      <div className="mb-4"><Button as={Link} to={`/mazerunner`} color='vk' size='massive'><Icon name='exchange' /> Mazerunner</Button></div>
    </div>
  );
}

export default Home;