import React from "react";

function NoMatch() {
    return (
      <div>
        <h2>Not found</h2>
      </div>
    );
}

export default NoMatch;