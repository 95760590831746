import React from "react"

function Records({records, displayTime}) {
  if (!records) records = []
  return (
    <div className='mt-5'>
        <hr/>
        <h3>Daily Best Times</h3>
        {records.map(record => {
          return <div key={record.id}>{displayTime(record.total_time)} - Tries: {record.guess_count}</div>
        })}
    </div>
  );
}

export default Records;