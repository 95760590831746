const getCharacter = () => {
  let character = Math.floor(Math.random() * 10)
  return String(character)
}

export const createCode = (codeLength) => {
  let codeToBreak = [...Array(codeLength)].map(()=> {
    return getCharacter()
  })
  return codeToBreak
}

export const displayTime = (miliseconds) => {
  return new Date(miliseconds).toISOString().substring(14, 22)
}