import './mazerunner.css';
import { Button, Icon } from 'semantic-ui-react'
import React, { useEffect, useState, useRef } from "react"
import CreateMaze from '../components/Mazerunner/createMaze'
import { displayTime } from '../utils/utils'
import PlayOptions from '../components/Mazerunner/PlayOptions'
// import { post } from '../utils/apiClient'

function Mazerunner() {
  const controlRef = useRef();
  // const webSocket = useRef(null);
  const [state, setState] = useState({
    is_playing: false,
    player_name: '',

    player: null,
    current_maze: null,
    is_complete: false,
    beginAt: null,
    endAt: null,

    maze_rows: 10,
    maze_columns: 10,
    show_maze: true,
    show_end: true
  })

  const mount = () => {
    async function load() {
      const current_maze = new CreateMaze(state.maze_rows, state.maze_columns, 3)
      // const player = new Player()
      const player = {column: 0, row: 0, color: "darkgreen"}
      const player_name = `Guest${Math.floor(Math.random() * 100) + 1}`
      setState({...state, current_maze, player, player_name})
    }
    load()
  }
  useEffect(mount, [])

  useEffect(() => {
    if(state.is_playing) {
      controlRef.current.focus()
    }
  }, [state.is_playing])

  const updatePlayer = (player_name) => {
    setState({...state, player_name})
  }
  const movePlayer = (direction) => {
    let {current_maze, player, is_playing} = state
    if (!is_playing) return
    let current_cell =  current_maze.cells[player.column][player.row]
    switch(direction) {
      case 'up':
        if (current_cell.wallTop === false && player.row > 0) player.row = player.row - 1
      break
      case 'down':
        if (current_cell.wallBottom === false && player.row < (state.maze_rows - 1)) player.row = player.row + 1
      break
      case 'left':
        if (current_cell.wallLeft === false && player.column > 0) player.column = player.column - 1
      break
      case 'right':
        if (current_cell.wallRight === false && player.column < (state.maze_columns - 1)) player.column = player.column + 1
      break
    }
    current_maze = updateSight(player, current_maze)
    setState({...state, player, is_playing, current_maze})
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      movePlayer('up')
      e.preventDefault()
    } else if (e.keyCode === 40) {
      movePlayer('down')
      e.preventDefault()
    } else if (e.keyCode === 39) {
      movePlayer('right')
      e.preventDefault()
    } else if (e.keyCode === 37) {
      movePlayer('left')
      e.preventDefault()
    }
  }

  const resetPlayer = () => {
    return {column: 0, row: 0, color: "darkgreen"}
  }

  const beginGame = () => {
    const player = resetPlayer()
    let current_maze = new CreateMaze(state.maze_rows, state.maze_columns, 3)
    current_maze = updateSight(player, current_maze)
    setState({...state, 
      is_playing: true, 
      is_complete: false, 
      player, 
      current_maze, 
      beginAt: Date.now()
    })
  }

  const createMaze = (maze_rows, maze_columns) => {
    let current_maze = new CreateMaze(maze_rows ?? state.maze_rows, maze_columns ?? state.maze_columns, 3)
    current_maze = updateSight(player, current_maze)
    return current_maze
  }

  const checkWin = () => {
    if (state.player.column == state.maze_columns - 1 && state.player.row == state.maze_rows - 1){
      console.log('WINNER!!!')
      setState({...state, 
        is_playing: false, 
        is_complete: true, 
        endAt: Date.now()
      })
    }
  }

  const updateMazeSettings = (mazeSettings) => {
    let {current_maze} = state
    if (mazeSettings.maze_rows && mazeSettings.maze_columns) current_maze = createMaze(mazeSettings.maze_rows, mazeSettings.maze_columns)
    setState({...state, current_maze, ...mazeSettings})
  }

  const updateSight = (player, current_maze) => {
    const player_column = player.column
    const player_row = player.row
    if (current_maze.cells?.[player_column]?.[player_row]) current_maze.cells[player_column][player_row].is_visible = true
    if (current_maze.cells?.[player_column+1]?.[player_row]) current_maze.cells[player_column+1][player_row].is_visible = true
    if (current_maze.cells?.[player_column-1]?.[player_row]) current_maze.cells[player_column-1][player_row].is_visible = true
    if (current_maze.cells?.[player_column]?.[player_row+1]) current_maze.cells[player_column][player_row+1].is_visible = true
    if (current_maze.cells?.[player_column]?.[player_row-1]) current_maze.cells[player_column][player_row-1].is_visible = true
    return current_maze
  }

  const {player_name, player, current_maze, is_playing, is_complete, show_maze, show_end} = state
  if (player && is_playing) checkWin()
  return (
    <div>
      <h2>Mazerunner</h2>
      <hr />
      <PlayOptions state={state} beginGame={beginGame} updateMazeSettings={updateMazeSettings} />

      {is_complete ? <div className='mt-3'>Maze completed in {displayTime(state.endAt - state.beginAt)}</div> : ''}
      <div className='themaze mt-4 mb-4'>
      <section>
        {current_maze ? current_maze.cells.map((row, rindex) => {
          return [row.map((column, cindex) => {
            const cell = current_maze.cells[cindex][rindex]
            let cellClass = ''
            if (show_maze || cell.is_visible === true) {
              if (cell.wallTop === true) cellClass = `wt`
              if (cell.wallBottom === true) cellClass = `${cellClass} wb`
              if (cell.wallLeft === true) cellClass = `${cellClass} wl`
              if (cell.wallRight === true) cellClass = `${cellClass} wr`
            }

            let character = ''
            if (rindex === player.row && cindex === player.column) {
              character = <Icon className={`maze-entity-${state.maze_rows}`} name='square' color='blue' />
            } else if ((show_end || cell.is_visible) && cindex == state.maze_columns - 1 && rindex == state.maze_rows - 1) {
              character = <Icon className={`maze-entity-${state.maze_rows}`} name='circle' color='green' />
            }

            return <div key={`r${rindex}c${cindex}`} className={`cell ${cellClass}`}>{character}</div>
          }),
          <hr key={rindex} />]
          
        }) 
        :''}
        </section>
      </div>

    {is_playing ? 
      <div className='button-controls'>
        <div className="button-row mb-3">
          <Button icon='redo' style={{visibility:'hidden'}} size='massive' className='mr-3'></Button>
          <Button onClick={()=>movePlayer('up')} onKeyDown={handleKeyDown} icon='arrow up' size='massive' ref={controlRef}></Button>
        </div>
        <div className="button-row">
          <Button onClick={()=>movePlayer('left')} icon='arrow left' size='massive' className='mr-3'></Button>
          <Button onClick={()=>movePlayer('down')} icon='arrow down' size='massive' className='mr-3'></Button>
          <Button onClick={()=>movePlayer('right')} icon='arrow right' size='massive'></Button>
        </div>    
      </div>
    : ''}

    </div>
  );
}

export default Mazerunner;