import React from "react"
import { Button } from 'semantic-ui-react'

function ButtonGrid({hitButton, hitDelete}) {
  return (
    <div className='button-grid'>
    {/* <Input type='text' onChange={inputChange} value={currentGuess} fluid /> */}
    <div className="button-row">
      <Button onClick={()=>hitButton('1')}>1</Button>
      <Button onClick={()=>hitButton('2')}>2</Button>
      <Button onClick={()=>hitButton('3')}>3</Button>
    </div>
    <div className="button-row">
      <Button onClick={()=>hitButton('4')}>4</Button>
      <Button onClick={()=>hitButton('5')}>5</Button>
      <Button onClick={()=>hitButton('6')}>6</Button>
    </div>
    <div className="button-row">
      <Button onClick={()=>hitButton('7')}>7</Button>
      <Button onClick={()=>hitButton('8')}>8</Button>
      <Button onClick={()=>hitButton('9')}>9</Button>
    </div>
    <div className="button-row">
      <Button onClick={()=>hitDelete()}>{'<'}</Button>
      <Button onClick={()=>hitButton('0')}>0</Button>
      <Button>&nbsp;&nbsp;</Button>
    </div>
    {/* <div className="button-row">
      <Button onClick={()=>guessCode()} className='submit-button'>Submit</Button>
    </div> */}
  </div>
  );
}

export default ButtonGrid;