import React from "react"
import { Button } from 'semantic-ui-react'
import CodeLengthButtons from "./CodeLengthButtons"

function Multiplayer({state, joinGroup, newGroup, updateCodeLength, updatePlayerCount, beginGame}) {

  const listGroups = () => {
    const {multiplayerGroups} = state
    let groups = []
    for (let key in multiplayerGroups) {
      const group = multiplayerGroups[key]
      if (group.name === 'lobby') continue
      groups.push(
        <div className="group-list-item" key={group.name}>
          {group.player_count} - {group.name}  
          <Button onClick={()=>joinGroup(group.name)} size="mini" className="ml-3">Join</Button>
        </div>
      )
    }
    return (
      <div className="mb-3">
        <div className="group-list-item">
          Lobby ({multiplayerGroups['lobby'].player_count})
        </div>
        <div><strong>Groups</strong></div>
        {groups}
        <div className="mt-3"><Button onClick={()=>newGroup()} size="mini">Add Group</Button></div>
      </div>
    )
  }

  const groupDetails = () => {
    const {currentGroup} = state

    let players_list = []
    for (let key in currentGroup.players) {
      const player = currentGroup.players[key]
      players_list.push(
        <div className="group-list-item" key={key}>
          {player.player_name}  
        </div>
      )
    }

    return (<div className="mb-3">
      <div><strong>Group</strong></div>
      {currentGroup.name}
      <div className="mt-3"><strong>Players</strong> ({currentGroup.player_count})</div>
      {players_list}
      
      <CodeLengthButtons state={state} updateCodeLength={updateCodeLength} />
      <Button onClick={()=>beginGame()} size='big' primary className="mt-4">Begin</Button> 

      <div className="mt-5">
        <Button onClick={()=>joinGroup('lobby')} size="mini">Leave Group</Button>
      </div>
    </div>)
  }

  const playerCountButtons = () => {
    const {selectedPlayerCount, minPlayerCount, maxPlayerCount} = state
    let countButtons = []
    for(let i = minPlayerCount; i <= maxPlayerCount; i++) {
      countButtons.push(<Button onClick={()=>updatePlayerCount(i)} primary={i === selectedPlayerCount ? true : false} key={`b${i}`}>{i}</Button>)
    }
    return countButtons
  }

  const {currentGroup} = state
  return (
    <div className="mb-3">
      {currentGroup.name === 'lobby' ? listGroups() : groupDetails()}
      {/* <div><strong>Players</strong></div>
        <ButtonGroup size='small'>
          {playerCountButtons()}
        </ButtonGroup> */}
      
    </div>
  );
}

export default Multiplayer;