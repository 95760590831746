import React, { useState, useEffect, useRef } from 'react';
import { Button, Header, Icon, Modal, Input, Message } from 'semantic-ui-react';

function PlayerModal({ current_name, updatePlayer, setEditPlayer }) {
  let refInput = useRef();
  const [state, setState] = useState({
    current_value: '',
    error_message: ''
  });

  const mount = () => {
    setState({...state, current_value: current_name})
  }
  useEffect(mount, [])

  const inputChange = (e, {name, value}) => {
    setState({...state, current_value: value})
  }
  const submitChange = () => {
    let {current_value} = state
    current_value = current_value.trim()
    if (!current_value) {
      setState({...state, error_message: 'Please provide a valid name.'})
      refInput.current.focus()
    } else {
      updatePlayer(current_value)
      setEditPlayer(false)
    }
  }

  return (
    <Modal open={true}>
      <Icon name='close' onClick={() => setEditPlayer(false)} />
        <Header>Name</Header>
        <Modal.Content>
            <Input type='text'fluid  onChange={inputChange} value={state.current_value} ref={refInput} />
            {state.error_message ? <Message negative><p>{state.error_message}</p></Message> : ''}
        </Modal.Content>
      <Modal.Actions>
        <Button content='Submit' primary onClick={() => submitChange()} loading={state.is_loading} />
        <Button content='Cancel' onClick={() => setEditPlayer(false)} loading={state.is_loading} />
      </Modal.Actions>
    </Modal>
  )
}

export default PlayerModal;
